import ResultsPage from '../components/results-page';

class SampleSearchWithEvents {
  constructor() {
    this._initSearch();
  }

  _initSearch() {
    this.resultPage = new ResultsPage();
  }
}
const app = new SampleSearchWithEvents();